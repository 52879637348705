import Particle from './Particle';

export default function NetworkSketch(p5) {
    let particles = [];

    p5.setup = () => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight);
        const particlesCount = Math.floor(p5.windowWidth / 5);
        for (let i = 0; i < particlesCount; i++) {
            particles.push(new Particle(p5, p5.random(p5.width), p5.random(p5.height)));
        }
    };

    p5.draw = () => {
        p5.background(0);
        particles.forEach((particle, index) => {
            particle.update();
            particle.display();
            particle.checkParticles(particles.slice(index));
        });
    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    };
}