import React, { useEffect, useRef } from 'react';
import p5 from 'p5';
import './P5SketchWrapper.css';

export const P5SketchWrapper = ({sketch, canvasID}) => {
    const sketchContainerRef = useRef();

    useEffect(() => {
        const sketchInstance = new p5(sketch, sketchContainerRef.current);
        
        return () => {
            sketchInstance.remove();
        };
    }, [sketch]);

    return <div ref={sketchContainerRef} id={canvasID} className='canvas-container'></div>;
}