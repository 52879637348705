import './HeroSection.css';

export function HeroSection({ onButtonClick }) {
    return (
      <div className="hero-section" style={{ height: '100vh' }}>
        <div className="hero-text">
          <h1>Introducing ADVA</h1>
          <p>Retail Roadmapping, Redefined.</p>
        </div>
        <img
          style={{height: '50px', width: '50px'}}
          src='/more-info.png'
          alt='Button'
          onClick={onButtonClick}
        />
      </div>
    );
  }