import { Bubble } from "./Bubble";

export function InfoSketch(p5) {
    let bubbles = [];
    let icons = [
        {
            label: 'Search Trends',
            src: '/source-icons/search-trends.png',
            img: null
        },
        {
            label: 'Social Media',
            src: '/source-icons/social-media.png',
            img: null
        },
        {
            label: 'Sales Data',
            src: '/source-icons/sales-data.png',
            img: null
        },
        {
            label: 'Sentiment Analysis',
            src: '/source-icons/sentiment-analysis.png',
            img: null
        },
        {
            label: 'News Outlets',
            src: '/source-icons/news-outlets.png',
            img: null
        }
    ];

    let adva_logo;
    let output_icon;
    let centerX;
    let centerY;
    let placement_radius = 250;
    let startAngle = p5.radians(30);
    let endAngle = p5.radians(150);
    let angleStep = (endAngle - startAngle) / (icons.length - 1);
    console.log('angleStep', angleStep);

    p5.preload = () => {
        icons.forEach(icon => {
            icon.img = p5.loadImage(icon.src,
                () => console.log('Image loaded successfully'),
                (err) => console.error('Failed to load image', err));
        });
        
        output_icon = p5.loadImage('/source-icons/output-icon.png');
        adva_logo = p5.loadImage('/adva-logo.png');
    };


    p5.setup = () => {

        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        centerX = p5.width * 2/3;
        centerY = p5.height / 2;
        for (let i = 0; i < icons.length; i++) {
            let angle = startAngle + i * angleStep;
            let x = centerX + placement_radius * p5.cos(angle);
            let y = centerY - placement_radius * p5.sin(angle);
            
            bubbles.push(new Bubble(p5, x, y, icons[i].label, icons[i].img));
        }

        // Add output bubble
        bubbles.push(new Bubble(p5, centerX, centerY + placement_radius, 'Precise Forecasting', output_icon));
    };

    p5.draw = () => {
        p5.background(0);

        // Draw each component bubble
        bubbles.forEach(bubble => bubble.draw());

        // Draw product name in the center
        p5.fill(255);
        p5.textSize(32);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.noStroke();
        p5.text('ADVA', p5.width * 2/3, p5.height / 2);
        p5.ellipse(p5.width * 2/3, p5.height / 2, 110, 110);
        p5.imageMode(p5.CENTER)
        p5.image(adva_logo, p5.width * 2/3, p5.height / 2, 100, 100);

        p5.noFill();
        p5.stroke(255);
}
}