export class Bubble {
    constructor(p5, x, y, label, img) {
        this.p5 = p5;
        this.position = p5.createVector(x, y);
        this.size = 60;
        this.label = label;

        this.maxX = x + 5;
        this.minX = x - 5;
        this.maxY = y + 5;
        this.minY = y - 5;

        this.xSpeed = p5.random(0.1, 0.3) * (-1)**p5.floor(p5.random(1, 3));
        this.ySpeed = p5.random(0.1, 0.3) * (-1)**p5.floor(p5.random(1, 3));

        this.img = img;
    }

    move() {
        // Update position
        this.position.x += this.xSpeed;
        this.position.y += this.ySpeed;

        // Check boundaries
        if (this.position.x > this.maxX || this.position.x < this.minX) {
            this.xSpeed *= -1;
        }

        if (this.position.y > this.maxY || this.position.y < this.minY) {
            this.ySpeed *= -1;
        }

    }

    display() {
        // Draw the line from the bubble to the center
        this.p5.stroke(255);
        this.p5.line(this.position.x, this.position.y, this.p5.width * 2/3, this.p5.height / 2);

        // Draw bubble with icon
        this.p5.fill(255);
        this.p5.stroke(255);
        this.p5.ellipse(this.position.x, this.position.y, this.size);

        // console.log(this.img)
        // Only draw the image if it's loaded
        this.p5.imageMode(this.p5.CENTER);
        if (this.img && this.img.width > 0) {
            let aspect_ratio = this.img.width / this.img.height;
            this.p5.image(this.img, this.position.x, this.position.y, this.size * 0.4 * aspect_ratio, this.size * 0.4);
        } else {
            console.log('Image not ready for drawing', this.label);
        }


        // Draw label below the bubble
        // this.p5.noStroke();
        // this.p5.fill(255);
        // this.p5.textAlign(this.p5.CENTER);
        // this.p5.text(this.label, this.position.x, this.position.y + this.size / 2 + 20);
    }
  
    draw() {
        this.move();
        this.display();
    }
}