import './MoreInfo.css'

export const MoreInfo = () => {
    return (
        <div className='wrapper'>
            <div className="more-info">
                <h2>Putting the AI in Retail</h2>
                <p>ADVA is an AI assistant designed to help you forecast demand for products, new and old alike. 
                    We leverage emergent LLM technology to synthesize data from across the web to produce more prescient predictions.
                    Use us to make intelligent business decisions, maximizing future revenue and mitigating risk.</p>
            </div>
        </div>
        
    );
}