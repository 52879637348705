export default class Particle {
    constructor(p5, x, y) {
        this.p5 = p5;
        this.pos = this.p5.createVector(x, y);
        this.size = this.p5.random(1, 5);
        this.vel = this.p5.createVector(this.p5.random(-2, 2), this.p5.random(-2, 2));
    }

    update() {
        this.pos.add(this.vel);
        this.edges();
    }

    display() {
        this.p5.stroke(200);
        this.p5.strokeWeight(2);
        this.p5.ellipse(this.pos.x, this.pos.y, this.size * 2);
    }

    edges() {
        if (this.pos.x < 0 || this.pos.x > this.p5.width) {
            this.vel.x *= -1;
        }

        if (this.pos.y < 0 || this.pos.y > this.p5.height) {
            this.vel.y *= -1;
        }
    }

    checkParticles(particles) {
        particles.forEach(particle => {
            const d = this.p5.dist(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y)
            if (d < 70) {
                this.p5.stroke(150);
                this.p5.line(this.pos.x, this.pos.y, particle.pos.x, particle.pos.y)
            }
        });
    }
}