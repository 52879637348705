import React from 'react';
import { FullPageScroll } from './FullPageScroll/FullPageScroll';
import { NavBar } from './NavBar/NavBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <FullPageScroll/>
      {/* <Container fluid style={{height: '100%'}} className='d-flex flex-column App'>
          <NavBar/>
        <Row>
        <NavBar/>
          <FullPageScroll />
        </Row>
      </Container>     */}
    </div>
  );
}

export default App;
