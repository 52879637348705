import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import './NavBar.css';

export function NavBar () {
    return (
        <nav className='navbar'>
            <div className='navbar-icon'>
                <img src="/adva-logo.png" style={{height: '30px'}}/>
                
            </div>
            <h1>| ADVA</h1>
            
        </nav>
        // <Row className='topnav'>
        //     <Col>
        //     <   img src="/adva-logo.png" className='icon'/>
        //     </Col>
        // </Row>
    )
}