import React, { useEffect, useRef } from 'react';
import 'fullpage.js/dist/fullpage.css';
import fullpage from 'fullpage.js';
import { HeroSection } from '../HeroSection/HeroSection';
import { P5SketchWrapper } from '../P5SketchWrapper/P5SketchWrapper';
import NetworkSketch from '../NetworkSketch/NetworkSketch';
import { InfoSketch } from '../InfoSketch/InfoSketch';
import { MoreInfo } from '../MoreInfo/MoreInfo';
import './FullPageScroll.css';

export const FullPageScroll = () => {
    const fullpageRef = useRef();

    useEffect(() => {
        console.log('fullpageRef', fullpageRef.current)
        // Initialize fullpage.js on ref element
        if (fullpageRef.current) {
            new fullpage(fullpageRef.current, {
                navigation: true,
                navigationPosition: 'right',
            })
        }

        // Cleanup on unmount
        return () => {
            if (window.fullpage_api) {
                window.fullpage_api.destroy('all');
            }
        };
    }, []);

    // Function to move ot the next section
    const moveToNextSection = () => {
        if (window.fullpage_api) {
            window.fullpage_api.moveSectionDown();
        }
    };

    return (
        <div id='fullpage-wrapper' ref={fullpageRef}>
            <div className='section'>
                <P5SketchWrapper sketch={NetworkSketch} canvasID='network-canvas'/>
                <HeroSection onButtonClick={moveToNextSection}/>
            </div>
            <div className='section'>
                <P5SketchWrapper sketch={InfoSketch} canvasID='info-canvas'/>
                <MoreInfo/>
            </div>
        </div>
    );
};